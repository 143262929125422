import React from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  Checkbox,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  TablePagination,
} from "@mui/material";
import Moment from "moment";
import Status from "job/components/Status";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import {
  ArrowDownwardRounded,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArrowUpwardRounded,
  PriorityHigh,
} from "@mui/icons-material";
import NFormat from "common/components//NumberFormat";
import SearchForm from "./SearchForm";

const TableData = ({
  data,
  handleCheckboxChange,
  handleChangePage,
  page,
  pageSize,
  handleChangeRowsPerPage,
  handleClickSorting,
  isAscOrder,
  onSubmitSearch,
  handleSearchFormInputChange,
  searchForm,
  handleClickClear,
  setSearchForm,
  builders,
}) => {
  return (
    <>
      <SearchForm
        onSubmitSearch={onSubmitSearch}
        handleSearchFormInputChange={handleSearchFormInputChange}
        searchForm={searchForm}
        handleClickClear={handleClickClear}
        setSearchForm={setSearchForm}
        builders={builders}
      />

      {data?.content?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            {data?.content && (
              <Table sx={{ minWidth: 650 }} aria-label="job table">
                <TableHead>
                  <TableRow>
                    {builders && <StyledTableCell></StyledTableCell>}
                    <StyledTableCell>S.No.</StyledTableCell>
                    <StyledTableCell>
                      <span
                        className="theadLink"
                        onClick={(e) => handleClickSorting(e, "jobDate")}
                      >
                        Job Date
                        {isAscOrder ? (
                          <ArrowDropUpOutlined />
                        ) : (
                          <ArrowDropDownOutlined />
                        )}
                      </span>
                      <br></br>
                      Created
                    </StyledTableCell>
                    <StyledTableCell>
                      Builder<br></br>Job Address
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Charged <br></br> Paid
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Balance <br></br> Due
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Installation<br></br> Charge
                    </StyledTableCell>                   
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Wood Expense
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Glass Expense
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Spindle Expense
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Misc. Expense
                    </StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      Profit/Loss
                    </StyledTableCell>
                    <StyledTableCell>Installer(s)</StyledTableCell>
                  </TableRow>
                </TableHead>
                {data.content.length > 0 ? (
                  <TableBody>
                    {data.content.map((row, index) => (
                      <StyledTableRow key={row.jobId}>
                        {builders && (
                          <StyledTableCell>
                            <Grid item xs={12} sm={12} md={4}>
                              <Checkbox
                                color="success"
                                size="small"
                                value={row.jobId || ""}
                                onChange={(e) => handleCheckboxChange(e)}
                                inputProps={{
                                  "aria-label": "status",
                                }}
                              />
                            </Grid>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {page * pageSize + index + 1}
                        </StyledTableCell>
                        <StyledTableCell>
                          {Moment(row.jobDate).format("YYYY-MM-DD")}
                          <br></br>
                          <div style={{ color: blue[200] }}>
                            {Moment(row.created).format("YYYY-MM-DD")}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.builder.name}
                          <br></br>
                          <Tooltip title="Edit Job">
                            <Link
                              to={{
                                pathname: `/job/editJob/${row.jobId}`,
                              }}
                            >
                              {row.jobAddress}
                            </Link>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <NFormat
                            prefix={"$"}
                            suffix={""}
                            number={row.quote}
                          />
                          <br></br>
                          <div style={{ color: green[300] }}>
                            <NFormat
                              prefix={"$"}
                              suffix={""}
                              number={row.paidAtm}
                            />
                          </div>
                        </StyledTableCell>

                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <div
                            style={{
                              color:
                                row.outstandingAtm > 0
                                  ? orange[600]
                                  : grey[500],
                            }}
                          >
                            <NFormat
                              prefix={"$"}
                              suffix={""}
                              number={row.outstandingAtm}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <NFormat
                            prefix={"$"}
                            suffix={""}
                            number={row.installationFee}
                          />
                        </StyledTableCell>                       
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <NFormat
                            prefix={"$"}
                            suffix={""}
                            number={row.woodExpense}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <NFormat
                            prefix={"$"}
                            suffix={""}
                            number={row.glassExpense}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <NFormat
                            prefix={"$"}
                            suffix={""}
                            number={row.spindleExpense}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          <NFormat
                            prefix={"$"}
                            suffix={""}
                            number={row.miscExpense}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Status jobStatus={row.jobStatus} />
                        </StyledTableCell>

                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              <NFormat
                                prefix={"$"}
                                suffix={""}
                                number={row.profitLossAtm}
                              />
                            </div>
                            <div>
                              {row.profitLossAtm > 0 ? (
                                <ArrowUpwardRounded
                                  sx={{ color: green[500] }}
                                />
                              ) : row.profitLossAtm < 0 ? (
                                <ArrowDownwardRounded
                                  sx={{ color: red[500] }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                color:
                                  row.profitLossAtm > 0
                                    ? "#4caf50"
                                    : row.profitLossAtm < 0
                                    ? "#f44336"
                                    : "",
                              }}
                            >
                              ({row.profitLossPercent}%)
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div>
                            {row.installers.map((item, index) => (
                              <div key={item.id}>
                                <div>{item.name}</div>
                              </div>
                            ))}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <Typography
                    variant="subtitle1"
                    sx={{ m: 2, color: grey[500] }}
                  >
                    No Data Available
                  </Typography>
                )}
              </Table>
            )}
          </TableContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <TablePagination
                component="div"
                sx={{ color: grey[500] }}
                count={data.totalElements}
                page={page}
                onPageChange={(event, value) => handleChangePage(event, value)}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="subtitle1" sx={{ m: 2, color: grey[500] }}>
          No Data Available
        </Typography>
      )}
    </>
  );
};

export default TableData;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: grey[500],
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: grey[400],
    paddingRight: 1,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: grey[900],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
