import { Clear, TableRows } from "@mui/icons-material";
import React, { useState } from "react";
import logo from "assets/images/logo_ARK.png";
import { useNavigate } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  const handleClickSignIn = () => {
    navigate("/login");
  };

  return (
    <div className="w-screen h-[90px] z-10 bg-[#0F0F0F] fixed drop-shadow-2xl">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center px-2">
          <img src={logo} loading="lazy" alt="logo" className="w-24 px-2 opacity-90" />
        </div>
        <div className="hidden md:flex pr-4">
          <ul className="hidden md:flex">
            <li>
              <Link className="nav" to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link
                className="nav"
                to="gallery"
                offset={-80}
                smooth={true}
                duration={500}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                className="nav"
                to="about"
                smooth={true}
                offset={-90}
                duration={500}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className="nav"
                to="contact"
                smooth={true}
                offset={-85}
                duration={500}
              >
                Contact
              </Link>
            </li>

            <li>
              <button
                onClick={handleClickSignIn}
                className="px-2 text-xs py-1 bg-amber-300  border-amber-300 rounded-3xl hover:bg-amber-400 hover:text-gray-700 text-gray-700 mr-4"
              >
                Sign In
              </button>
            </li>
          </ul>
        </div>
        <div className="md:hidden px-4" onClick={handleClick}>
          {!nav ? (
            <TableRows className="w-5 text-amber-400" />
          ) : (
            <Clear className="w-5  text-amber-400" />
          )}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            className="cursor-pointer hover:text-gray-600"
            onClick={handleClose}
            to="home"
            smooth={true}
            duration={500}
          >
            Home
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            className="cursor-pointer hover:text-gray-600"
            onClick={handleClose}
            to="gallery"
            offset={-80}
            smooth={true}
            duration={500}
          >
            Gallery
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            className="cursor-pointer hover:text-gray-600"
            onClick={handleClose}
            to="about"
            offset={-80}
            smooth={true}
            duration={500}
          >
            About
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            className="cursor-pointer hover:text-gray-600"
            onClick={handleClose}
            offset={-60}
            to="contact"
            smooth={true}
            duration={500}
          >
            Contact
          </Link>
        </li>

        <div className="flex flex-col my-4">
          <button
            onClick={handleClickSignIn}
            className="bg-amber-300 shadow-lg font-bold rounded-3xl border-amber-300 hover:shadow-lg hover:bg-amber-400 text-gray-700
              hover:text-gray-700 px-8 py-3 mb-4"
          >
            Sign In
          </button>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
