import { blue, green, grey, orange, red } from "@mui/material/colors";
import React from "react";
import ApexChart from "react-apexcharts";

const Chart = ({ donutChartDataSet, totalJob }) => {
  const donutChartData = {
    options: {
      tooltip: {
        enabled: true,
      },
      title: {
        text: "JOB STATUS",
        align: "center",
        style: {
          color: grey[500],
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          color: grey[500],
          fontSize: "14px",
          fontFamily: "Roboto,Helvetica, Arial, sans-serif",
          fontWeight: "600",
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          return w.config.series[seriesIndex];
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.5,
        },
      },
      labels: ["New", "In Progress", "Completed"],
      colors: [blue[500], orange[500], green[500]],
      stroke: {
        show: false,
      },
      legend: {
        position: "bottom",
        fontFamily: "Roboto,Helvetica, Arial",
        fontWeight: 500,
        fontSize: 14,
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      noData: {
        text: "No Data Available",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                fontSize: 18,
                fontWeight: 600,
                style: {
                  color: grey[100],
                },
                formatter: function (w) {
                  return (
                    w.globals.seriesTotals[0] +
                    w.globals.seriesTotals[1] +
                    w.globals.seriesTotals[2]
                  );
                },
              },
            },
          },
        },
      },
    },
    series: donutChartDataSet,
  };
  return (
    <div>
      <ApexChart
        type="donut"
        width="100%"
        series={donutChartData.series}
        options={donutChartData.options}
      />
    </div>
  );
};

export default Chart;
