import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";
import AppButton from "common/components/Button";
import { Grid } from "@mui/material";
import ModalService from "modals/service/ModalService";
import ConfirmModal from "common/components/ConfirmModal";
import ModalRoot from "modals/components/ModalRoot";
import AppContext from "context/AppContext";
import GenericTable from "common/components/GenericTable";
import api_service from "common/utils/Api";
import { grey } from "@mui/material/colors";
import Heading from "common/components/Heading";
import { responseErrorMsg } from "common/utils/ResponseError";
import Error from "common/components/Error";
import { AddBox } from "@mui/icons-material";
import AlertMsg from "common/components/AlertMsg";

const Supplier = () => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [suppliersData, setSuppliersData] = useState([]);
  const { value, setIsLoading } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.message || errors?.message) setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 4000);
  }, [location, errors?.message]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getSuppliers(signal);

    return () => {
      controller.abort();
    };
  }, [value]);

  const getSuppliers = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getSuppliers(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setSuppliersData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleClickNew = () => {
    navigate("/supplier/newSupplier");
  };

  return (
    <>
      <div className="padding_1rem">
        <ModalRoot />

        <Grid
          container
          sx={{
            border: 1,
            m: 0,
            color: grey[900],
            borderStyle: "none none solid none",
          }}
        >
          <Grid item xs={12}>
            <Heading
              variant={"h3"}
              title={`Vendor [${suppliersData.length}]`}
              sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
            />
          </Grid>
        </Grid>

        <AlertMsg
          message={errors?.message ? errors.message : location?.state?.message}
          open={open}
          severity={errors?.message ? "error" : "success"}
        />

        <Error text={errors?.message} />

        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <AppButton
              icon={<AddBox />}
              btnValue={"Add New"}
              type="button"
              variant={"contained"}
              handleClicked={handleClickNew}
            ></AppButton>
          </Grid>
        </Grid>

        <GenericTable
          tableHeaders={[
            "S.No.",
            "Name",
            "Email",
            "Phone",
            "Address",
            "Action",
          ]}
          showSN={true}
          tableData={suppliersData}
          dataAttribute={["name", "email", "phone", "address"]}
          deleteConfirmModal={deleteSupplierModal}
          path="/supplier/editSupplier"
          toolTipTxt="Edit Supplier"
        />
      </div>
    </>
  );
};

export default Supplier;

const deleteSupplierModal = (supplier) => {
  const props = {
    message:
      "Are you sure you want to delete supplier, since every job associated with this supplier will also be deleted.  ",
    placeholder: supplier.name,
    supplierId: supplier.id,
    type: "supplier",
    title: "Delete Supplier Confirmation",
  };
  ModalService.open(ConfirmModal, props);
};
