import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "sidebar/components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: grey[500],
    },
  },
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
    fontSize: 12,
  },
  spacing: 4,
});

const RootLayout = () => {
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="w-full flex">
          <nav>
            <Sidebar />
          </nav>
          <main>
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </>
  );
};

export default RootLayout;
