import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";
import AppButton from "common/components/Button";
import { Grid } from "@mui/material";
import ModalService from "modals/service/ModalService";
import ConfirmModal from "common/components/ConfirmModal";
import AppContext from "context/AppContext";
import GenericTable from "common/components/GenericTable";
import api_service from "common/utils/Api";
import { grey } from "@mui/material/colors";
import Heading from "common/components/Heading";
import ModalRoot from "modals/components/ModalRoot";
import { responseErrorMsg } from "common/utils/ResponseError";
import Error from "common/components/Error";
import { AddBox } from "@mui/icons-material";
import AlertMsg from "common/components/AlertMsg";

const Builder = () => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { value, setIsLoading } = useContext(AppContext);
  const [builders, setBuilders] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.message || errors?.message) setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 4000);
  }, [location, errors.message]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getBuilders(signal);

    return () => {
      controller.abort();
    };
  }, [value]);

  const getBuilders = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getBuilders(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setBuilders(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleClickNew = () => {
    navigate("/builder/newBuilder");
  };

  return (
    <>
      <ModalRoot />
      <Grid
        container
        sx={{
          border: 1,
          m: 0,
          color: grey[900],
          borderStyle: "none none solid none",
        }}
      >
        <Grid item xs={12}>
          <Heading
            variant={"h3"}
            title={`Builder [${builders.length}]`}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </Grid>
      </Grid>
      <AlertMsg
        message={errors?.message ? errors.message : location?.state?.message}
        open={open}
        severity={errors?.message ? "error" : "success"}
      />
      <Error text={errors?.message} />
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <AppButton
            icon={<AddBox />}
            btnValue={"Add New"}
            type="button"
            variant={"contained"}
            handleClicked={handleClickNew}
          ></AppButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <GenericTable
            tableHeaders={[
              "S.No.",
              "Name",
              "Email",
              "Phone",
              "Address",
              "Action",
            ]}
            tableData={builders}
            dataAttribute={["name", "email", "phone", "address"]}
            deleteConfirmModal={deleteBuilderModal}
            path="/builder/editBuilder"
            showSN={true}
            toolTipTxt="Edit Builder"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Builder;

const deleteBuilderModal = (builder) => {
  const props = {
    message:
      "Are you sure you want to delete builder, since every job associated with this builder will also be deleted. ",
    placeholder: builder.name,
    builderId: builder.id,
    type: "builder",
    title: "Delete Builder Confirmation",
  };

  ModalService.open(ConfirmModal, props);
};
