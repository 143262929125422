import { Grid, Paper, Typography } from "@mui/material";
import NumberFormat from "common/components/NumberFormat";
import React from "react";

export default function OverviewReport({ title, amount, sxProp }) {
  return (
    <>
      <Paper elevation={3} sx={{ minWidth: 200, p: 2, mx: 12, my: 2 }}>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "center", p: 2 }}>
            <div className="text-4xl text-gray-500 font-bold">
              <NumberFormat prefix={"$"} number={amount} />
            </div>

            <Typography variant="h6" sx={sxProp}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
