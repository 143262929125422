import validator from "validator";

const Service = {
  jobStatus: [
    { id: "C", name: "Completed" },
    { id: "I", name: "In Progress" },
  ],
  COMPLETED: "C",
  INPROGRESS: "I",
  NEW: "N",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_USER: "ROLE_USER",
  INSTALLER: "installer",
  BUILDER: "builder",
  JOB: "job",
  COMMENT: "comment",
  SUPPLIER: "supplier",
  HOME: "home",
  province: [
    { id: "AB", name: "Alberta" },
    { id: "BC", name: "British Columbia" },
    { id: "MB", name: "Manitoba" },
    { id: "NB", name: "New Brunswick" },
    { id: "NL", name: "Newfoundland and Labrador" },
    { id: "NS", name: "Nova Scotia" },
    { id: "ON", name: "Ontario" },
    { id: "PE", name: "Prince Edward Island" },
    { id: "SK", name: "Saskatchewan" },
    { id: "QC", name: "Quebec" },
    { id: "YT", name: "Yukon" },
    { id: "NT", name: "Northwest Territories" },
    { id: "NU", name: "Nunavut" },
  ],
  getIndex(id, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        return i;
      }
    }
    return "";
  },
  isEmailValid(email) {
    return email && validator.isEmail(email) ? "" : this.INVALID_EMAIL;
  },
  isPostalCodeValid(postalCode) {
    return postalCode && validator.isPostalCode(postalCode, "CA")
      ? ""
      : this.INVALID_POSTAL_CODE;
  },
  isNumber(value) {
    return value && !isNaN(value) ? "" : this.NOT_NUMERIC_VALUE;
  },
};

export default Service;
