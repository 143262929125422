import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import Error from "common/components/Error";
import GenericTable from "common/components/GenericTable";
import Heading from "common/components/Heading";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import React, { useEffect, useContext, useState } from "react";

const Payment = () => {
  const { setIsLoading } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [paymentInfo, setPaymentInfo] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getPaymentInfo(signal);

    return () => {
      controller.abort();
    };
  },[]);

  const getPaymentInfo = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getPaymentInfo(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setPaymentInfo(response.data)
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  return (
    <>
      <div className="padding_1rem">
        <Grid
          container
          sx={{
            border: 1,
            m: 0,
            color: grey[900],
            borderStyle: "none none solid none",
          }}
        >
          <Grid item xs={12}>
            <Heading
              variant={"h3"}
              title={`Payment Information`}
              sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
            />
          </Grid>
        </Grid>
        <Error text={errors?.message} />

        <GenericTable
          tableData={paymentInfo}
          tableHeaders={["S.No.","Installer", "Outstanding Payment"]}
          showSN={true}
          dataAttribute={["name", "paymentAmt"]}
          path="/payment/details"
          
        />
      </div>
    </>
  );
};

export default Payment;
