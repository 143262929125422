import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { tableCellClasses } from "@mui/material/TableCell";
import { grey, red } from "@mui/material/colors";
import Data from "./Data";
import { Padding } from "@mui/icons-material";

const GenericTable = ({
  tableHeaders,
  tableData,
  dataAttribute,
  deleteConfirmModal,
  path,
  toolTipTxt,
  showSN,
}) => {
  return (
    <div>
      {tableData && tableData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((headerName, index) => (
                  <StyledTableCell
                    sx={{
                      textAlign:
                        headerName === "Tax" || headerName === "Earned"
                          ? "center"
                          : headerName === "Outstanding Payment"
                          ? "right"
                          : "left",
                    }}
                    key={index}
                  >
                    {headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <StyledTableRow key={row.id ? row.id : index}>
                  {showSN && <StyledTableCell>{index + 1}</StyledTableCell>}

                  <Data
                    data={dataAttribute}
                    path={path}
                    row={row}
                    toolTipTxt={toolTipTxt}
                    rowIndex={index}
                  />

                  {deleteConfirmModal && (
                    <StyledTableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteConfirmModal(row)}
                        >
                          <DeleteForeverIcon
                            className="error"
                            sx={{ color: red[400] }}
                          />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="subtitle1" sx={{ m: 2, color: grey[500] }}>
          No Data Available
        </Typography>
      )}
    </div>
  );
};

export default GenericTable;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: grey[500],
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: grey[400],
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: grey[900],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
